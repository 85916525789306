




























































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { getScreenSizeVariant } from '@/vue-app/utils/screen';
import ContractSavingsProfilingPersonalInformationViewModel
  from '@/vue-app/view-models/components/contract-savings/profiling/personal-information/contract-savings-profiling-personal-information-view-model';
import CustomAutocomplete from '@/vue-app/components/CustomAutocomplete.vue';

// Domain

@Component({
  name: 'ContractSavingsProfilingPersonalInformation',
  components: {
    CustomAutocomplete,
    ContractSavingsActions: () => import('@/vue-app/components/contract-savings/ContractSavingsActions.vue'),
  },
})
export default class ContractSavingsProfilingPersonalInformation extends Vue {
  profiling_personal_information_view_model
    = Vue.observable(new ContractSavingsProfilingPersonalInformationViewModel());

  @Watch('profiling_personal_information_view_model.is_loading')
  onLoadChange(is_loading: boolean) {
    this.$emit('loadingInfo', is_loading);
  }

  @Watch('profiling_personal_information_view_model.inputs.tax_residence_outside_mexico')
  onTaxResidenceOutsideMexicoChange(tax_residence_outside_mexico: string) {
    if (tax_residence_outside_mexico !== 'yes') {
      this.profiling_personal_information_view_model.inputs.usa_citizen = '';
      this.profiling_personal_information_view_model.inputs.tax_id = '';
      this.profiling_personal_information_view_model.inputs.foreign_residence_country = {
        codPais: null,
        descPais: null,
      };
    }
  }

  @Watch('profiling_personal_information_view_model.inputs.usa_citizen')
  onUSACitizenChange(usa_citizen: string) {
    if (usa_citizen !== 'yes') {
      this.profiling_personal_information_view_model.inputs.tax_id = '';
    }
  }

  prevStep() {
    this.$emit('prevStep');
  }

  async nextStep() {
    const saved_step = await this.profiling_personal_information_view_model.saveStep();
    if (saved_step) {
      this.$emit('nextStep');
    }
  }

  async created() {
    this.profiling_personal_information_view_model.small_screen = getScreenSizeVariant() === 'small';
    await this.profiling_personal_information_view_model.initialize();
  }
}

